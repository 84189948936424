
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DialogDelete from '@/components/dialogs/delete-dialog.component.vue';
import { Role } from '../../entities/role.entity';
import profilePermisionService from '../permissions/services/profile-permision.service';
import { ProfileEnum } from './enums/profile-enum.enum';

@Component({ components: { AlertError, DialogDelete, SimplePagination } })
export default class ProfileComponent extends Vue {
  private roles: Role[] = [];

  private errors: any[] = [];

  private get headers(): any {
    if (this.$permissionAccess.group.isSuperAdmin) {
      return [
        { text: 'Nome', value: 'name', width: '40%' },

        {
          text: 'Empresa',
          value: 'company_name',
        },
        {
          text: 'Ativo',
          value: 'activeText',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ];
    }
    return [
      { text: 'Nome', value: 'name', width: '80%' },
      {
        text: 'Ativo',
        value: 'activeText',
      },
      {
        text: 'Ações',
        value: 'actions',
      },
    ];
  }

  private isSuperAdministrator(item: any) {
    return item !== ProfileEnum.SUPER_ADMINISTRATOR;
  }

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private async getRoles() {
    const roles = await profilePermisionService.getRolesPaginated();
    this.roles = roles
      .map((it: any) => new Role(it))
      .filter((i: any) => this.isSuperAdministrator(i.name));
  }

  public async backPagination() {
    if (!profilePermisionService.simplePaginationService.prevLink) return;
    const roles = await profilePermisionService.simplePaginationService.prev();
    this.roles = roles
      .map((it: any) => new Role(it))
      .filter((i: any) => this.isSuperAdministrator(i.name));
  }

  public async nextPagination() {
    if (!profilePermisionService.simplePaginationService.nextLink) return;
    const roles = await profilePermisionService.simplePaginationService.next();
    this.roles = roles
      .map((it: any) => new Role(it))
      .filter((i: any) => this.isSuperAdministrator(i.name));
  }

  public async goToFirst() {
    if (!profilePermisionService.simplePaginationService.firstLink) return;
    const roles = await profilePermisionService.simplePaginationService.goToFirst();
    this.roles = roles
      .map((it: any) => new Role(it))
      .filter((i: any) => this.isSuperAdministrator(i.name));
  }

  private goToFormEdit(role: Role) {
    this.$router.push({
      name: 'manager-permissions',
      params: {
        id: role.id,
      },
    });
  }

  private createNewProfile() {
    this.$router.push({
      name: 'manager-permissions',
    });
  }

  private created() {
    this.getRoles();
  }
}
